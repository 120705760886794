<template>
  <div :active.sync="isActive">
    <div class="tile is-parent">
      <div class="tile is-parent is-half">
        <div class="field">
          <label class="label">Customer Name</label>
          <span>{{ smsDetails.customer }}</span>
        </div>
      </div>
      <div class="tile is-parent is-half">
        <div class="field">
          <label class="label">Rego</label>
          <span>{{ smsDetails.rego }}</span>
        </div>
      </div>
    </div>

    <article class="box">
      <div class="field">
        <div class="field-body">
          <div class="field is-narrow">
            <label class="label">Mobile</label>
            <p class="control has-icons-left is-expanded">
              <the-mask 
                :class="{ 'is-danger' : !isValidMobile}"
                @input="enterMobile"
                v-model="smsDetails.mobile"
                :mask="phoneNoMask"
                :tokens="phoneNoTokens"
                class="input"
                placeholder="Country Code + Number"
                v-focus-inserted />
              <span class="icon is-small is-left">
                <i class="mdi mdi-phone mdi-18px" />
              </span>
              <span 
                class="help is-danger"
                v-if="!isValidMobile">Mobile is required</span>
            </p>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Message</label>
        <div class="control is-expanded">
          <multiselect 
            v-model="selectedMessage"
            track-by="message"
            label="message"
            placeholder="Select Message"
            :options="messages"
            :loading="isMessageLoading"
            :searchable="true"
            :allow-empty="true"
            :options-limit="messageFilter.pageSize"
            @select="selectMessage"
            @remove="removeMessage" />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <textarea 
            class="textarea"
            rows="8"
            :class="{ 'is-danger' : !isMessageEntered}"
            @input="enterMessage"
            v-model="smsMessage"
            placeholder="Enter Cutom Message Here..." />
          <span 
            class="help is-danger"
            v-if="!isMessageEntered">Message is required.</span>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import { FocusInserted } from '@/components/directives'
import Multiselect from 'vue-multiselect'
import SmsService from './SmsService'

export default {
  name: 'SmsSend',
  components: {
    TheMask,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  props: {
    enableSend: false,
    value: null,
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false,
      isValidMobile: false,
      isMessageEntered: false,
      smsDetails: null,
      smsMessage: '',
      phoneNoTokens: {
        P: {
          pattern: /[0-9, ()\-+]/
        }
      },
      phoneNoMask: Array(31).join('P'), // length of phoneNo field in database
      messages: [],
      selectedMessage: null,
      isMessageLoading: false,
      messageFilter: {
        message: '',
        pageIndex: 1,
        pageSize: 999
      }
    }
  },
  created() {
    this.smsDetails = this.value
    this.getSmsMessageDropdown()
    this.messageFilter.message = this.value.message
    this.smsMessage = this.value.message

    if (this.value.mobile !== '') {
      this.isValidMobile = true
    } else {
      this.isValidMobile = false
    }

    if (this.value.message !== '') {
      this.isMessageEntered = true
    } else {
      this.isMessageEntered = false
    }
    this.$emit('smsdetails-entered', this.isMessageEntered && this.isValidMobile)
  },
  mounted() {},
  methods: {
    enterMobile(mobileNumber) {
      this.isValidMobile = mobileNumber !== ''
      this.smsDetails.mobile = mobileNumber
      this.$emit('smsdetails-entered', this.isMessageEntered && this.isValidMobile)
    },
    enterMessage() {
      this.isMessageEntered = this.smsMessage !== ''
      this.smsDetails.message = this.smsMessage
      this.$emit('smsdetails-entered', this.isMessageEntered && this.isValidMobile)
    },
    async send() {
      this.$showSpinner('Sending...')
      try {
        await SmsService.sendSms(this.smsDetails)
        this.$notification.openNotificationWithType('success', 'Send SMS', 'Message Sent')
      } catch (e) {
        this.$notification.openMessageXhrError('Send SMS', e)
      } finally {
        this.$hideSpinner()
      }
    },
    async getSmsMessageDropdown() {
      this.isMessageLoading = true
      this.messages = await SmsService.getSmsMessageDropdownProto(this.messageFilter)
      if (this.messageFilter.message !== '') {
        this.selectedMessage = this.messages.find(c => c.message === this.messageFilter.message)
      }
      this.isMessageLoading = false
    },
    async selectMessage(selected) {
      this.messageFilter.message = selected.message
      this.smsMessage = selected.message
      this.enterMessage()
    },
    removeMessage() {
      this.messageFilter.message = ''
      this.smsMessage = ''
      this.enterMessage()
      this.getSmsMessageDropdown()
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
