import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseMessageFilter(filter)
    const url = `/sms/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseMessageFilter(filter) {
    const entityProto = require('../../assets/proto/sms/SmsMessageFilter.proto')
    const filterMessage = entityProto.SmsMessageFilter.SmsMessageFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getSmsMessageDropdownProto(filter) {
    const serialised = this.serialiseSmsMessageDropdownFilter(filter)
    const result = await ProtoBufApi(`/sms/protobuf/sendsms/listcombo/${serialised}`)
    const proto = require('../../assets/proto/sms/SmsMessageDropdown.proto')
    const message = proto.SmsMessageDropdown.SmsMessageDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseSmsMessageDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/sms/SmsMessageDropdownFilter.proto')
    const filterMessage = entityProto.SmsMessageDropdownFilter.SmsMessageDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async sendSms(info) {
    const result = await FqApi.post('/sms/send', info)
    return result.data
  },
  postEntity(entity) {
    return FqApi.post('/sms/new', entity)
  },
  putEntity(entity) {
    return FqApi.put(`/sms/${entity.id}`, entity)
  },
  deleteEntity(id) {
    return FqApi.delete('/sms/' + id)
  }
}
