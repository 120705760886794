<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">
              Details
              <span v-show="!innerValue.isNew"
                class="icon has-text-info tooltip is-tooltip-info"
                :class="[ canChangeReadOnly ? 'readonly-pointer' : '' ]"
                :data-tooltip="innerValue.readOnly ? 'Quote is locked' : 'Quote is unlocked'"
                @click="toogleReadOnly()">
                <i class="icon mdi"
                  :class="[ innerValue.readOnly ? 'mdi-lock-outline' : 'mdi-lock-open-outline' ]" />
              </span>
            </p>
          </div>
          <div class="column is-narrow">
            <div class="tags">
              <span v-if="invoice"
                class="tag is-success is-pulled-right">Inv {{ `${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}/${invoice.invoiceType}` }} dated {{ $filters.formatDateLocale(invoice.invoiceDate, $userInfo.locale) }}</span>
              <span v-if="excessInvoice"
                class="tag is-success is-pulled-right">Excess Inv {{ `${excessInvoice.prefix}${excessInvoice.invoiceNo}${excessInvoice.suffix}/${excessInvoice.invoiceType}` }} dated {{ $filters.formatDateLocale(excessInvoice.invoiceDate, $userInfo.locale) }}</span>
            </div>
          </div>
        </div>
        <div class="field-body">
          <div class="field">
            <label class="label">Quote No.</label>
            <div class="control">
              <input class="input is-static"
                :value="`${innerValue.prefix}${innerValue.quoteNo}${innerValue.suffix}`"
                type="text"
                placeholder="Quote No."
                readonly>
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">Quote Date</label>
            <input v-if="readOnlyView"
              class="input is-static"
              :value="$filters.formatDateLocale(quoteDate, $userInfo.locale)"
              readonly>
            <v-date-picker v-else
              v-model="selectedQuoteDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="quoteDateInputEvent"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Quote Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <div class="columns mb-0">
            <div class="column py-2">
              <label class="label mb-0">Vehicle</label>
            </div>
            <div class="column is-flex is-justify-content-flex-end py-2">
              <div v-show="ntarLtarStatus"
                class="field">
                <span class="tag is-success animate__animated animate__pulse animate__repeat-3 tooltip"
                  :data-tooltip="`${ntarLtarStatus} schedule and times found`">{{ ntarLtarStatus }} Hours On</span>
              </div>
            </div>
          </div>
          <div v-if="readOnlyView"
            class="field-body">
            <div class="field has-addons">
              <div class="control">
                <input class="input is-static"
                  :value="vehicle ? vehicle.rego : ''"
                  readonly>
              </div>
              <div class="control"
                style="margin-top: 2.5px">
                <span v-if="selectedVehicle"
                  class="tag is-info is-medium">{{ vehicle ? vehicle.model.name.replace(/  +/g, ' ') : '' }}</span>
              </div>
            </div>
          </div>
          <div v-else
            class="field has-addons">
            <div class="control is-expanded">
              <multiselect v-if="vehicles"
                id="quote-vehicle-multiselect"
                :class="{ 'is-danger' : $v.entity.vehicleId.$error}"
                v-model="selectedVehicle"
                deselect-label="Can't remove this value"
                track-by="vehicleId"
                label="rego"
                placeholder="Search By Rego..."
                :options="vehicles"
                :loading="isVehicleLoading"
                :internal-search="false"
                :options-limit="vehicleFilter.pageSize"
                @select="selectVehicle"
                @remove="removeVehicle"
                @search-change="getVehicleDropdownFilter"
                :searchable="true"
                :show-labels="false"
                :allow-empty="false">
                <template slot="singleLabel"
                  slot-scope="{ option }">
                  {{ option.rego }}
                  <span v-if="selectedVehicle"
                    class="tag is-info is-pulled-right">{{ selectedVehicle.model }}</span>
                </template>
                <template slot="option"
                  slot-scope="props">
                  <span class="tag is-pulled-right is-info">
                    {{ props.option.model }}
                  </span>
                  <span>{{ props.option.rego }}</span>
                </template>
                <span class="has-text-danger"
                  slot="noResult">Vehicle not found.</span>
              </multiselect>
              <span class="help is-danger"
                v-if="$v.entity && $v.entity.vehicleId.$error">Vehicle is required.</span>
            </div>
            <div class="control">
              <a class="button"
                :class="vehicleButtonClass"
                @click="onEditAddVehicle()">
                <span class="icon">
                  <i class="mdi mdi-24px"
                    :class="vehicleButtonIconClass" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div v-if="readOnlyView"
              class="field">
              <label class="label">Insurer</label>
              <input class="input is-static"
                :value="quoteInsurer ? quoteInsurer.name : ''"
                readonly>
            </div>
            <div v-else
              class="field">
              <label class="label">Insurer</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <multiselect v-if="insurers"
                    :class="{ 'is-danger' : $v.entity.insurerId.$error}"
                    v-model="selectedInsurer"
                    deselect-label="Can't remove this value"
                    track-by="assetId"
                    label="assetName"
                    placeholder="Search By Name..."
                    :options="insurers"
                    :loading="isInsurerLoading"
                    :internal-search="false"
                    :options-limit="insurerFilter.pageSize"
                    @select="selectInsurer"
                    @remove="removeInsurer"
                    @search-change="getInsurerDropdownFilter"
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="false"
                    :disabled="innerValue.readOnly || audanetLock">
                    <template slot="option"
                      slot-scope="props">
                      <span>{{ props.option.assetName }}</span>
                    </template>
                    <span class="has-text-danger"
                      slot="noResult">Insurer not found.</span>
                  </multiselect>
                  <span class="help is-danger"
                    v-if="$v.entity && $v.entity.insurerId.$error">Insurer is required.</span>
                </div>
                <div class="control is-narrow">
                  <button class="button"
                    :class="insurerButtonClass"
                    @click="onEditAddInsurer()"
                    :disabled="innerValue.readOnly || audanetLock">
                    <span class="icon">
                      <i class="mdi mdi-24px"
                        :class="insurerButtonIconClass" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Labour Type</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="innerValue.labourType"
                  readonly>
              </div>
              <div v-else
                class="control">
                <div class="select">
                  <select v-model="innerValue.labourType"
                    :disabled="innerValue.readOnly || audanetLock">
                    <option v-for="(labourTime, prop) in labourTimeTypes"
                      :key="prop"
                      :value="prop">{{ labourTime }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Quoter</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static is-capitalized"
                  :value="innerValue.quoter"
                  readonly>
              </div>
              <div v-else
                class="control">
                <bulma-autocomplete placeholder="Quoter"
                  v-model="innerValue.quoter"
                  :data="filteredQuoters"
                  :keep-first="true"
                  :loading="false"
                  @input="$v.detailGroup.$touch()"
                  :class-prop="'is-capitalized' + (!$v.entity.quoter.$error ? '' : ' is-danger')" />
                <span class="help is-danger"
                  v-if="$v.entity && $v.entity.quoter.$error">Quoter is required.</span>
              </div>
            </div>
            <div class="field">
              <label class="label">Job Stage</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="jobStages.find(j => j.stage === innerValue.jobStage).description"
                  readonly>
              </div>
              <div v-else
                class="control is-expanded">
                <div class="select is-fullwidth">
                  <select v-model="innerValue.jobStage">
                    <option v-for="(jobStage) in jobStages"
                      :value="jobStage.stage"
                      :key="jobStage.stage">
                      {{ jobStage.enumDescription | formatJobStage }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Method</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="value.quotingMethod === quotingMethodTypes.Hour ? 'Hour' : 'Dollar'"
                  readonly>
              </div>
              <div v-else
                class="control pt-1">
                <div class="pretty p-default p-round p-bigger p-smooth">
                  <input v-model="value.quotingMethod"
                    type="radio"
                    name="rd-quotingMethod"
                    :value="'H'"
                    :disabled="innerValue.readOnly || audanetLock">
                  <div class="state p-primary-o">
                    <label>Hour</label>
                  </div>
                </div>
                <div class="pretty p-default p-round p-bigger p-smooth">
                  <input v-model="value.quotingMethod"
                    type="radio"
                    name="rd-quotingMethod"
                    :value="'D'"
                    :disabled="innerValue.readOnly || audanetLock">
                  <div class="state p-primary-o">
                    <label>Dollar</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Claim No.</label>
              <div class="control">
                <input class="input is-uppercase"
                  :class="{ 'is-static' : readOnlyView }"
                  v-model="innerValue.claimNo"
                  type="text"
                  :placeholder="!readOnlyView ? 'Claim No.' : ''"
                  :disabled="audanetLock">
              </div>
            </div>
            <div class="field">
              <label class="label">Policy No.</label>
              <div class="control">
                <input class="input is-uppercase"
                  :class="{ 'is-static' : readOnlyView }"
                  v-model="innerValue.policyNo"
                  type="text"
                  :placeholder="!readOnlyView ? 'Policy No.' : ''">
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Authorised Date</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="$filters.formatDateLocale(innerValue.authorisedDate, $userInfo.locale)"
                  readonly>
              </div>
              <v-date-picker v-else
                v-model="selectedAuthorisedDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="authorisedDateInputEvent"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Authorised Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="field">
              <label class="label">Assessor</label>
              <div class="control">
                <input class="input is-capitalized"
                  :class="{ 'is-static' : readOnlyView }"
                  v-model="innerValue.assessorName"
                  type="text"
                  :placeholder="!readOnlyView ? 'Assessor' : ''">
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <div class="is-flex is-justify-content-space-between">
                <label class="label">Excess</label>
                <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                  <label class="pretty-checkbox-label pr-1 is-size-7"
                    for="chk-excessWtihGst">Excess with GST</label>
                  <div class="pretty p-icon p-round p-smooth m-0">
                    <input v-model="innerValue.excessWithGst"
                      id="chk-excessWtihGst"
                      type="checkbox"
                      :disabled="innerValue.readOnly || readOnlyView || audanetLock">
                    <div class="state p-primary">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="excess | formatNumber($userInfo.locale)"
                  readonly>
              </div>
              <div v-else
                class="control">
                <vue-numeric class="input has-text-right"
                  v-model="excess"
                  @blur="calculateExcess()"
                  :min="0"
                  placeholder="Excess"
                  :precision="2"
                  :disabled="innerValue.readOnly || audanetLock" />
              </div>
              <!-- </div> -->
            </div>
            <div class="field">
              <label class="label">Owner's Contribution</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="innerValue.ownersContribution | formatNumber($userInfo.locale)"
                  readonly>
              </div>
              <div v-else
                class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.ownersContribution"
                  :min="0"
                  placeholder="Owner's Contribution"
                  :precision="2"
                  :disabled="innerValue.readOnly || audanetLock" />
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Discount on invoice</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="innerValue.discount | formatNumber($userInfo.locale)"
                  readonly>
              </div>
              <div v-else
                class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.discount"
                  :min="0"
                  formats
                  placeholder="Discount applied on invoice"
                  :precision="2"
                  :disabled="innerValue.readOnly || audanetLock" />
              </div>
            </div>
            <div class="field">
              <label class="label">Est. Start &amp; End Date</label>
              <div v-if="readOnlyView"
                class="control">
                <span>{{ $filters.formatDateLocale(innerValue.jobStart, $userInfo.locale) }} - {{ $filters.formatDateLocale(innerValue.jobEnd, $userInfo.locale) }}</span>
              </div>
              <v-date-picker v-else
                v-model="selectedJobStartEnd"
                ref="datepicker"
                mode="dateTime"
                :minute-increment="5"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @drag="jobStartEndDragEvent($event)"
                @input="jobStartEndInputEvent($event)"
                :select-attribute="selectDragAttribute"
                :drag-attribute="selectDragAttribute"
                :model-config="selectedJobStartEnd ? {} : modelConfig"
                :timezone="$filters.getIanaTimezone()"
                is-range>
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="field-body is-align-items-center">
                    <div class="field mr-1 has-addons">
                      <div class="control has-icons-left">
                        <input class="input"
                          :class="{'has-text-grey': isDragging}"
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                    <span class="icon">
                      <i class="mdi mdi-arrow-right" />
                    </span>
                    <div class="field ml-1 has-addons">
                      <div class="control has-icons-left">
                        <input class="input"
                          :class="{'has-text-grey': isDragging}"
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                      <div class="control">
                        <a class="button tooltip"
                          data-tooltip="Reset dates"
                          @click="resetJobStartEnd()">
                          <span class="icon">
                            <i class="mdi mdi-close mdi-24px" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="dragValue"
                  slot="day-popover"
                  slot-scope="{ format }">
                  <div>
                    {{ format(dragValue ? dragValue.start : selectedJobStartEnd.start, 'MMM D') }}
                    -
                    {{ format(dragValue ? dragValue.end : selectedJobStartEnd.end, 'MMM D') }}
                  </div>
                  <div style="display: flex; justify-content: center; align-items: center; padding-top: 3px">
                    <span class="icon is-small"
                      style="color: orange">
                      <i class="mdi mdi-weather-sunny mdi-18px" />
                    </span>
                    <span style="padding-left: 3px">{{ getNoOfDays(dragValue ? dragValue.start : selectedJobStartEnd.start, dragValue ? dragValue.end : selectedJobStartEnd.end) }}
                      day{{ getNoOfDays(dragValue ? dragValue.start : selectedJobStartEnd.start, dragValue ? dragValue.end : selectedJobStartEnd.end) > 1 ? 's': '' }}
                    </span>
                  </div>
                </div>
              </v-date-picker>
            </div>
          </div>
        </div>
        <div v-if="!innerValue.isNew && !readOnlyView && $user.hasDelete($route.meta.id)">
          <div class="is-divider" />
          <div class="buttons has-addons is-centered">
            <span class="button"
              :class="{ 'is-success is-selected' : !innerValue.deleted }"
              @click="toggleActive(true)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span class="button"
              :class="{ 'is-danger is-selected' : innerValue.deleted }"
              @click="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </div>
      </article>
    </div>
    <div class="tile is-parent is-half">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Summary</p>
        <quote-detail-summary v-model="value" />
      </article>
    </div>
  </div>
</template>

<script>
import QuoteDetailSummary from './QuoteDetailSummary'
import VueNumeric from '@/components/VueNumeric'
import Multiselect from 'vue-multiselect'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { JobStageTypes, QuotingMethodTypes, ItemCategoryTypes, InvoiceTypes, LabourTimeTypes } from '@/enums'
import QuoteFiltersMixins from './QuoteFiltersMixins'
import QuoteService from './QuoteService'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'
import StoreMixin from './storeMixin'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { InsurerPayload, GstModel } from '@/classes'
import QuoteTemplateService from '@/views/quotetemplate/QuoteTemplateService'
import { QuoteItemModel } from '@/classes/viewmodels'
import { QuoteLabourMixin, QuoteOtherLabourMixin } from './mixins'
import VehicleService from '@/views/vehicle/VehicleService'

export default {
  name: 'QuoteDetail',
  inject: ['$vv'],
  components: {
    QuoteDetailSummary,
    VueNumeric,
    Multiselect,
    BulmaAutocomplete
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, QuoteFiltersMixins, StoreMixin, QuoteLabourMixin, QuoteOtherLabourMixin],
  props: {
    value: null,
    vehicle: null,
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      quoteDate: null,
      jobStart: null,
      jobEnd: null,
      excess: 0,
      // authorisedDate: null,
      innerValue: null,
      vehicles: [], // Vehicle arrays for dropdown
      selectedVehicle: null,
      isVehicleLoading: false,
      vehicleFilter: {
        vehicleId: Guid.empty(),
        rego: '',
        pageIndex: 1,
        pageSize: 50
      },
      vehicleButtonLoading: false,
      insurers: [],
      selectedInsurer: null,
      isInsurerLoading: false,
      insurerFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: false,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      insurerButtonLoading: false,
      quoters: [],
      selectedQuoteDate: null,
      selectedJobStart: null,
      selectedJobEnd: null,
      selectedJobStartEnd: null,
      selectedAuthorisedDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        start: {
          timeAdjust: '08:00:00'
        },
        end: {
          timeAdjust: '08:00:00'
        }
      },
      dragValue: null,
      ntarLtarStatus: ''
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    jobStageTypes() {
      return JobStageTypes
    },
    hasVehicleSelected() {
      return this.selectedVehicle && !_isEmpty(this.selectedVehicle)
    },
    vehicleButtonIconClass() {
      return {
        'mdi-pencil': this.hasVehicleSelected,
        'mdi-plus': !this.hasVehicleSelected
      }
    },
    vehicleButtonClass() {
      return {
        'is-primary': this.hasVehicleSelected,
        'is-success': !this.hasVehicleSelected,
        'is-loading': this.vehicleButtonLoading
      }
    },
    hasInsurerSelected() {
      return this.selectedInsurer && !_isEmpty(this.selectedInsurer)
    },
    insurerButtonIconClass() {
      return {
        'mdi-pencil': this.hasInsurerSelected,
        'mdi-plus': !this.hasInsurerSelected
      }
    },
    insurerButtonClass() {
      return {
        'is-primary': this.hasInsurerSelected,
        'is-success': !this.hasInsurerSelected,
        'is-loading': this.insurerButtonLoading
      }
    },
    filteredQuoters() {
      return this.quoters.filter((option) => {
        return option.toString().toLowerCase().indexOf(this.innerValue.quoter.toLowerCase()) >= 0
      })
    },
    invoice() {
      return this.innerValue.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Quote)
    },
    excessInvoice() {
      return this.innerValue.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Excess)
    },
    labourTimeTypes() {
      return LabourTimeTypes
    },
    canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: 'hover',
          isInteractive: false // Defaults to true when using slot
        }
      }
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = newVal
      },
      deep: true
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    // authorisedDate: function(newVal, oldVal) {
    //   // console.log(newVal === '__/__/____')
    //   if (newVal && newVal !== '__/__/____') {
    //     const date = DateTime.fromFormat(newVal, getLocaleDateFormat(this.$userInfo.locale), { locale: this.$userInfo.locale })
    //     if (date.isValid) {
    //       this.innerValue.authorisedDate = date.toISO({ includeOffset: false, suppressMilliseconds: true }) + 'Z'
    //     } else {
    //       this.innerValue.authorisedDate = undefined
    //     }
    //   } else {
    //     this.innerValue.authorisedDate = null
    //   }
    // },
    rrTotal(newVal) {
      this.innerValue.rrTotal = this.rrTotal
    },
    repairTotal(newVal) {
      this.innerValue.repairTotal = this.repairTotal
    },
    rwaTotal(newVal) {
      this.innerValue.rwaTotal = this.rwaTotal
    },
    paintTotal(newVal) {
      this.innerValue.paintTotal = this.paintTotal
    },
    cdTotal(newVal) {
      this.innerValue.cdTotal = this.cdTotal
    },
    crushTotal(newVal) {
      this.innerValue.crushTotal = this.crushTotal
    },
    mechTotal(newVal) {
      this.innerValue.mechTotal = this.mechTotal
    },
    fgTotal(newVal) {
      this.innerValue.fgTotal = this.fgTotal
    },
    'innerValue.quotingMethod': function (newVal, oldVal) {
      if (oldVal) {
        const vm = this
        // Recalculates labours
        this.innerValue.labours.forEach(function (item, index) {
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            if (item.rev !== '1') {
              const rate = vm.getRate(item.itemType)
              item.rate = rate
            }
            item.hourValue = item.rate !== 0 ? roundAwayFromZero(item.dollarValue / item.rate) : 0
            item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          } else {
            item.hourValue = item.dollarValue
            // item.rate = 1
          }
        })
        // Recalculates other labours
        this.innerValue.others.forEach(function (item, index) {
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            if (item.rev !== '1') {
              const rate = vm.getRate(item.itemType)
              item.rate = rate
            }
            item.hourValue = item.rate !== 0 ? roundAwayFromZero(item.dollarValue / item.rate) : 0
            item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          } else {
            item.hourValue = item.dollarValue
          }
        })
        // Recalculates OPG
        this.innerValue.opgs.forEach(function (item, index) {
          if (item.rev !== '1') {
            const rate = vm.getRate(item.opgCode)
            item.rate = rate
          }
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            item.hourValue = item.rate !== 0 ? roundAwayFromZero(item.dollarValue / item.rate) : 0
            item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          } else {
            item.hourValue = item.dollarValue
          }
        })
      }
    },
    selectedQuoteDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.quoteDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.quoteDate = null
      }
    },
    selectedJobStart: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.jobStart = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.jobStart = null
      }
    },
    selectedJobEnd: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.jobEnd = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.jobEnd = null
      }
    },
    selectedJobStartEnd: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.jobStart = newVal.start.toISOString().split('.')[0] + 'Z'
        this.innerValue.jobEnd = newVal.end.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.jobEnd = null
        this.innerValue.jobStart = null
      }
    },
    selectedAuthorisedDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.authorisedDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.authorisedDate = null
      }
    },
    'innerValue.vehicleId': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getNtarLtarStatus()
      }
    }
  },
  created() {
    this.innerValue = this.value
    this.excess = this.innerValue.excess
    this.quoteDate = new Date(`${this.innerValue.quoteDate}`)
    this.selectedQuoteDate = new Date(`${this.innerValue.quoteDate}`)
    if (this.innerValue.jobStart) {
      this.selectedJobStartEnd = {}
      this.jobStart = new Date(`${this.innerValue.jobStart}`)
      this.selectedJobStart = new Date(`${this.innerValue.jobStart}`)
      this.selectedJobStartEnd.start = new Date(`${this.innerValue.jobStart}`)
    }
    if (this.innerValue.jobEnd) {
      this.jobEnd = new Date(`${this.innerValue.jobEnd}`)
      this.selectedJobEnd = new Date(`${this.innerValue.jobEnd}`)
      this.selectedJobStartEnd.end = new Date(`${this.innerValue.jobEnd}`)
    }
    // this.authorisedDate = new Date(`${this.innerValue.authorisedDate}`).toLocaleDateString(this.$userInfo.locale)
    if (this.innerValue.authorisedDate) {
      this.selectedAuthorisedDate = new Date(`${this.innerValue.authorisedDate}`)
    }
    // this.authorisedDate = DateTime.fromISO(this.innerValue.authorisedDate)
    //   .setLocale(this.$userInfo.locale)
    //   .toLocaleString()

    this.vehicleFilter.vehicleId = this.innerValue.vehicleId
    this.insurerFilter.assetId = this.innerValue.insurerId
    this.getVehicleDropdown()
    this.getInsurerDropdown()
    this.getQuoters()
    this.getNtarLtarStatus()
  },
  mounted() {
    this.$vv.detailGroup.$touch()
    // const vehicleInputElement = document.getElementById('quote-vehicle-multiselect')
    // vehicleInputElement.style.cssText = `${vehicleInputElement.style.cssText}; text-transform: uppercase;`
  },
  methods: {
    onSelectQuoteDate(date, dateNow) {
      this.innerValue.quoteDate = dateNow.toISOString().split('.')[0] + 'Z'
    },
    onSelectJobStartDate(date, dateNow) {
      this.innerValue.jobStart = dateNow.toISOString().split('.')[0] + 'Z'
    },
    onSelectJobEndDate(date, dateNow) {
      this.innerValue.jobEnd = dateNow.toISOString().split('.')[0] + 'Z'
    },
    async getVehicleDropdown() {
      this.isVehicleLoading = true
      this.vehicles = await QuoteService.getVehicleDropdownProto(this.vehicleFilter)
      if (this.vehicleFilter.vehicleId !== Guid.empty()) {
        this.selectedVehicle = this.vehicles.find((c) => c.vehicleId === this.innerValue.vehicleId)
      }
      this.isVehicleLoading = false
    },
    getVehicleDropdownFilter: _debounce(async function (query) {
      this.isVehicleLoading = true
      this.vehicleFilter.rego = query
      this.vehicles = await QuoteService.getVehicleDropdownProto(this.vehicleFilter)
      this.isVehicleLoading = false
    }, 500),
    async selectVehicle(selected) {
      this.innerValue.vehicleId = selected.vehicleId
      this.innerValue.customerId = selected.customerId
      this.removeQuoteCustomer()
      this.removeQuoteVehicle()
      this.getQuoteCustomer({ id: selected.customerId, refresh: false })
      this.getQuoteVehicle({ id: selected.vehicleId, refresh: true })
      this.vehicleFilter.vehicleId = selected.vehicleId
      this.vehicleFilter.rego = ''
      this.vehicles = await QuoteService.getVehicleDropdownProto(this.vehicleFilter)
      this.$vv.detailGroup.$touch()
      // this.$v.entity.vehicleId.$touch()
    },
    removeVehicle() {
      this.$vv.detailGroup.$touch()
      this.vehicleFilter.vehicleId = Guid.empty()
      this.innerValue.vehicleId = Guid.empty()
      this.$vv.detailGroup.$touch()
      // this.$v.entity.vehicleId.$touch()
      this.getVehicleDropdown()
    },
    async getInsurerDropdown() {
      this.isInsurerLoading = true
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
      if (this.insurerFilter.assetId !== Guid.empty()) {
        this.selectedInsurer = this.insurers.find((c) => c.assetId === this.innerValue.insurerId)
      }
      this.isInsurerLoading = false
    },
    getInsurerDropdownFilter: _debounce(async function (query) {
      this.isInsurerLoading = true
      this.insurerFilter.assetName = query
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
      this.isInsurerLoading = false
    }, 500),
    async selectInsurer(selected) {
      const isItemsEmpty =
        this.innerValue.labours.length === 0 &&
        this.innerValue.parts.length === 0 &&
        this.innerValue.others.length === 0 &&
        this.innerValue.opgs.length === 0 &&
        this.innerValue.miscs.length === 0 &&
        this.innerValue.sublets.length === 0
      // if (this.innerValue.isNew) {
      //   this.innerValue.miscs.splice(0, this.innerValue.miscs.length)
      // }
      if (isItemsEmpty || this.innerValue.isNew) {
        // Update insurer related values when there are no items
        // const labourTypes = await QuoteService.getLabourTypes()
        this.$showSpinner()
        // const startTime = new Date()
        await this.getQuoteInsurer({ id: selected.assetId, refresh: true })
        // const endTime = new Date()
        // let timeDiff = endTime - startTime
        // // timeDiff /= 1000
        // const seconds = Math.round(timeDiff)
        // console.log(`${seconds} ms`)
        this.$hideSpinner()
        // Update quote
        this.innerValue.insurerId = selected.assetId
        this.innerValue.newMarkup = this.quoteInsurer.newMarkup
        this.innerValue.usedMarkup = this.quoteInsurer.usedMarkup
        this.innerValue.recoreMarkup = this.quoteInsurer.recoreMarkup
        this.innerValue.afterMarketMarkup = this.quoteInsurer.afterMarketMarkup
        this.innerValue.exchangeMarkup = this.quoteInsurer.exchangeMarkup
        this.innerValue.reconditionedMarkup = this.quoteInsurer.reconditionedMarkup
        this.innerValue.shopRate = this.quoteInsurer.shopRate
        this.innerValue.labourType = this.quoteInsurer.labourType
        this.innerValue.excessWithGst = this.quoteInsurer.excessGST
        this.innerValue.verRtrm = Math.max.apply(
          Math,
          this.quoteInsurer.insurerConsumables.map(function (c) {
            return c.versionNo
          })
        )
        // Update quote rates
        this.addInsurerLabourRates(this.quoteInsurer)
        // Add or update automatic items to quote
        this.addInsurerAutoItems(this.quoteInsurer)
        // Add or update template items to quote
        this.addInsurerTemplateItems(this.quoteInsurer)
      }
      this.innerValue.insurerId = selected.assetId
      this.insurerFilter.assetId = selected.assetId
      this.insurerFilter.assetName = ''
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
      this.$vv.detailGroup.$touch()
      // this.$v.entity.insurerId.$touch()
    },
    removeInsurer() {
      this.insurerFilter.assetId = Guid.empty()
      this.innerValue.insurerId = Guid.empty()
      this.$vv.detailGroup.$touch()
      // this.$v.entity.insurerId.$touch()
      this.getInsurerDropdown()
    },
    authorisedDateInput(args) {
      this.$vv.detailGroup.$touch()
      // this.$v.entity.authorisedDate.$touch()
    },
    onEditAddInsurer() {
      this.insurerButtonLoading = true
      this.setInsurerReturnRoute(this.$route)
      if (this.value.insurerId !== Guid.empty()) {
        this.editStoreInsurer(this.value.insurerId)
      } else {
        const payload = new InsurerPayload(this.insurerFilter.assetName)
        this.addStoreInsurer(payload)
      }
    },
    onEditAddVehicle() {
      this.vehicleButtonLoading = true
      this.setVehicleReturnRoute(this.$route)
      if (this.value.vehicleId !== Guid.empty()) {
        this.editStoreVehicle(this.value.vehicleId)
      } else {
        const payload = { rego: this.vehicleFilter.rego }
        this.addStoreVehicle(payload)
      }
    },
    async getQuoters() {
      this.quoters = await QuoteService.getQuoters()
    },
    getRate(code) {
      let rateObject = null
      if (code === ItemCategoryTypes.PAINT) {
        rateObject = this.innerValue.rates.find((i) => i.labourCodeId === this.vehicle.paintGroup)
      } else {
        rateObject = this.innerValue.rates.find((i) => i.labourCodeId === code)
      }
      const rate = !this.isNtar ? rateObject.rate : this.innerValue.shopRate
      return rate
    },
    addInsurerLabourRates(insurer) {
      const vm = this
      const isInsurerNtar =
        insurer.labourType === LabourTimeTypes.NTAR || insurer.labourType === LabourTimeTypes.LTAR || insurer.labourType === LabourTimeTypes.eMTA
      insurer.insurerLabourRates.forEach(function (ir) {
        const index = vm.innerValue.rates.findIndex((qr) => qr.labourCodeId === ir.labourCode)
        if (index > -1) {
          vm.innerValue.rates[index].rate = isInsurerNtar ? insurer.shopRate : ir.rate
        } else {
          const labourType = vm.$labourTypes.find((t) => t.labourTypeCode === ir.labourType)
          const newRate = {
            rate: isInsurerNtar ? insurer.shopRate : ir.rate,
            quoteVersion: 0,
            quoteId: vm.innerValue.quoteId,
            labourCodeId: ir.labourCode,
            labourTypeId: labourType.labourTypeId,
            deleted: false,
            createdBy: '',
            createdDate: '',
            modifiedBy: '',
            modifiedDate: '',
            isNew: true,
            isDeleted: false
          }
          vm.innerValue.rates.splice(vm.innerValue.rates.length, 1, newRate)
        }
      })
    },
    addInsurerAutoItems(insurer) {
      if (insurer.insurerAutoItems && insurer.insurerAutoItems.length > 0) {
        const vm = this
        let lineNumber = this.nextLineNumber
        insurer.insurerAutoItems.forEach(function (autoItem) {
          let currentItem = vm.innerValue.miscs.find((i) => i.itemDesc === autoItem.itemDesc && i.itemNo === autoItem.itemNo)
          if (!currentItem) {
            let newItem = new QuoteItemModel(vm.innerValue.quoteId, autoItem.itemNo, autoItem.itemDesc, ItemCategoryTypes.MISC)
            newItem.value = autoItem.price
            newItem.reportOnly = autoItem.reportOnly
            newItem.sortNo = vm.innerValue.miscs.length ? Math.max(...vm.innerValue.miscs.map((i) => i.sortNo)) + 1 : 1
            newItem.lineNumber = lineNumber
            vm.innerValue.miscs.splice(vm.innerValue.miscs.length, 1, newItem)
            console.log(lineNumber)
            lineNumber++
            vm.innerValue.lines++
          } else {
            currentItem.value = autoItem.price
            currentItem.reportOnly = autoItem.reportOnly
          }
        })
      }
    },
    async addInsurerTemplateItems(insurer) {
      if (Guid.validGuid(insurer.templateId)) {
        const templateItems = await QuoteTemplateService.getQuoteTemplateItems(insurer.templateId)
        if (templateItems && templateItems.length > 0) {
          const vm = this
          templateItems.forEach(function (templateItem) {
            let currentItem = vm.innerValue.miscs.find(
              (i) => i.itemDesc === templateItem.itemDesc && i.itemNo === templateItem.itemNo && i.itemType === templateItem.itemType
            )
            if (!currentItem) {
              let newItem = new QuoteItemModel(vm.innerValue.quoteId, templateItem.itemNo, templateItem.itemDesc, templateItem.itemType)
              newItem.value = templateItem.value
              newItem.reportOnly = templateItem.reportOnly
              newItem.sortNo = templateItem.sortNo
              // let sortNo
              switch (templateItem.itemType) {
                case ItemCategoryTypes.RR:
                case ItemCategoryTypes.REP:
                case ItemCategoryTypes.RWA:
                  newItem.rate = vm.getLabourRate(templateItem.itemType)
                  vm.innerValue.labours.splice(vm.innerValue.labours.length, 1, newItem)
                  break
                case ItemCategoryTypes.PAINT:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.innerValue.labours.splice(vm.innerValue.labours.length, 1, newItem)
                  // sortNo = vm.innerValue.labours.length ? Math.max(...vm.innerValue.labours.map(i => i.sortNo)) + 1 : 1
                  break
                case ItemCategoryTypes.OPG:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.innerValue.opgs.splice(vm.innerValue.opgs.length, 1, newItem)
                  // sortNo = vm.innerValue.opgs.length ? Math.max(...vm.innerValue.opgs.map(i => i.sortNo)) + 1 : 1
                  break
                case ItemCategoryTypes.MECH:
                case ItemCategoryTypes.CD:
                case ItemCategoryTypes.CRUSH:
                case ItemCategoryTypes.FIBER:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.innerValue.others.splice(vm.innerValue.others.length, 1, newItem)
                  // sortNo = vm.innerValue.others.length ? Math.max(...vm.innerValue.others.map(i => i.sortNo)) + 1 : 1
                  break
                case ItemCategoryTypes.PART:
                  vm.innerValue.parts.splice(vm.innerValue.parts.length, 1, newItem)
                  // sortNo = vm.innerValue.parts.length ? Math.max(...vm.innerValue.parts.map(i => i.sortNo)) + 1 : 1
                  break
                case ItemCategoryTypes.MISC:
                  vm.innerValue.miscs.splice(vm.innerValue.miscs.length, 1, newItem)
                  // sortNo = vm.innerValue.labours.length ? Math.max(...vm.innerValue.labours.map(i => i.sortNo)) + 1 : 1
                  break
                case ItemCategoryTypes.SUBL:
                  vm.innerValue.sublets.splice(vm.innerValue.sublets.length, 1, newItem)
                  // sortNo = vm.innerValue.sublets.length ? Math.max(...vm.innerValue.sublets.map(i => i.sortNo)) + 1 : 1
                  break
              }
              // newItem.sortNo = templateItem.sortNo // || sortNo
            } else {
              currentItem.value = templateItem.value
              currentItem.itemType = templateItem.itemType
              currentItem.reportOnly = templateItem.reportOnly
              currentItem.sortNo = templateItem.sortNo
            }
          })
        }
      }
    },
    toggleActive(active) {
      this.innerValue.deleted = !this.innerValue.deleted
    },
    resetJobStartEnd() {
      if (this.selectedJobStartEnd !== null) {
        this.selectedJobStartEnd = null
      }
    },
    calculateExcess() {
      if (this.innerValue.excessWithGst && this.excess !== this.innerValue.excess) {
        const gstModel = new GstModel(this.innerValue.gstRate)
        const excess = roundAwayFromZero(this.excess / gstModel.multiplier)
        this.excess = excess
        this.innerValue.excess = excess
      } else if (this.excess !== this.innerValue.excess) {
        this.innerValue.excess = this.excess
      }
    },
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
        this.innerValue.readOnly = !this.innerValue.readOnly
        this.innerValue.readOnlyStatuses.splice(this.innerValue.subQuoteNo, 1, this.innerValue.readOnly)
      }
    },
    getLabourRate(code) {
      if (!this.innerValue.rates) {
        return {}
      }
      let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((i) => i.labourCodeId === code)
      return !rate ? 0 : rate.rate
    },
    getNoOfDays(startDate, endDate) {
      const d1 = DateTime.fromISO(startDate.toISOString())
      const d2 = DateTime.fromISO(endDate.toISOString())
      return d2.diff(d1, 'days').toObject().days + 1
    },
    async getNtarLtarStatus() {
      this.ntarLtarStatus = await VehicleService.getNtarLtarStatusById(this.innerValue.vehicleId)
    },
    jobStartEndDragEvent(event) {
      this.dragValue = event
    },
    jobStartEndInputEvent(event) {},
    quoteDateInputEvent(event) {
      this.selectedQuoteDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    },
    authorisedDateInputEvent(event) {
      this.selectedAuthorisedDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}

.is-divider {
  margin: 1.2em 0;
}
</style>

<style lang="scss">
#quote-vehicle-multiselect {
  text-transform: uppercase !important;
}

.readonly-pointer {
  cursor: pointer;
}
</style>

