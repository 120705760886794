import { TableColumn } from '@/classes'

const QuoteTemplateListColumns = [
  new TableColumn('Name', 'name', true, 'asc', 'left'),
  new TableColumn('Type', 'quoteType', true, 'asc', 'left'),
  new TableColumn('Created Date', 'createdDate', true, 'desc', 'left'),
  new TableColumn('Labour Times', 'timesType', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const LabourColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'left'),
  new TableColumn('Type', 'itemType', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const AvailableLabourColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const MiscColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'left'),
  new TableColumn('Charge', 'value', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const SubletColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'left'),
  new TableColumn('Charge', 'value', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]
export { AvailableLabourColumns, LabourColumns, MiscColumns, QuoteTemplateListColumns, SubletColumns }