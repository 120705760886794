<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="45"
    :min-height="85"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>{{ smsModalHeader }}</span>
      </p>
    </header>
    <section class="modal-card-body">
      <sms-send v-if="isSendSmsModalActive"
        :active.sync="isSendSmsModalActive"
        v-model="smsDetails"
        ref="smsSend"
        @smsdetails-entered="smsDetailsEntered" />

      <sms-message v-if="isSmsMessageModalActive"
        :active.sync="isSmsMessageModalActive"
        ref="smsMessage"
        :enable-save="enableSave"
        @back="backToSmsSend"
        @save-button="setEnableSave"
        @close="close" />
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div align="right"
          style="width: 100%">
          <div v-if="isSendSms">
            <button class="button is-primary tooltip"
              @click="editTemplate()"
              data-tooltip="Edit message template">
              <span class="icon">
                <i class="mdi mdi-pencil mdi-18px" />
              </span>
              <span>Edit Template</span>
            </button>
            <button class="button is-success tooltip"
              @click="send()"
              :disabled="!enableSend"
              data-tooltip="Send">
              <span class="icon">
                <i class="mdi mdi-send mdi-18px" />
              </span>
              <span>Send</span>
            </button>
            <button class="button tooltip"
              @click="close(true)"
              data-tooltip="Close">
              <span class="icon">
                <i class="mdi mdi-check mdi-18px" />
              </span>
              <span>Close</span>
            </button>
          </div>
          <div v-else>
            <button v-if="enableSave"
              class="button is-success tooltip"
              @click="save()"
              data-tooltip="Save changes">
              <span class="icon">
                <i class="mdi mdi-content-save mdi-18px" />
              </span>
              <span>Save</span>
            </button>
            <button v-if="enableSave"
              class="button tooltip"
              @click="cancel()"
              data-tooltip="Cancel changes">
              <span class="icon">
                <i class="mdi mdi-close mdi-18px" />
              </span>
              <span>Cancel</span>
            </button>
            <button v-if="!enableSave"
              class="button is-primary tooltip"
              @click="add()"
              data-tooltip="Add new message template">
              <span class="icon">
                <i class="mdi mdi-plus mdi-18px" />
              </span>
              <span>New Message</span>
            </button>
            <button class="button tooltip"
              @click="back"
              data-tooltip="Back to Send SMS">
              <span class="icon">
                <i class="mdi mdi-arrow-left-bold mdi-18px" />
              </span>
              <span>Back</span>
            </button>
            <button class="button tooltip"
              @click="close(false)"
              data-tooltip="Close">
              <span class="icon">
                <i class="mdi mdi-check mdi-18px" />
              </span>
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import SmsSend from './SmsSend'
import SmsMessage from './SmsMessage'

export default {
  name: 'SmsModal',
  components: {
    BaseModalEx,
    SmsSend,
    SmsMessage
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-message-text'
    }
  },
  data() {
    return {
      smsDetails: null,
      isActive: false,
      enableSave: false,
      isSendSms: true,
      isEnteringSmsInfo: false,
      isSendSmsModalActive: true,
      isSmsMessageModalActive: false,
      smsModalHeader: ''
    }
  },
  computed: {
    enableSend() {
      return this.isEnteringSmsInfo
    }
  },
  created() {
    this.smsDetails = this.value
    if (this.isSendSms) {
      this.smsModalHeader = 'Send SMS'
    } else {
      this.smsModalHeader = 'SMS Message'
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    editTemplate() {
      this.isSendSmsModalActive = false
      this.isSmsMessageModalActive = true
      this.isSendSms = false
      this.enableSave = false
      this.smsModalHeader = 'SMS Message'
    },
    async send() {
      await this.$refs.smsSend.send()
      this.$emit('update:active', false)
      this.$emit('close')
    },
    close(isClose) {
      if (isClose) {
        this.$emit('update:active', false)
        this.$emit('close')
      } else {
        this.$refs.smsMessage.close()
      }
    },
    add() {
      this.enableSave = true
      this.$refs.smsMessage.add()
    },
    back() {
      this.$refs.smsMessage.back()
    },
    save() {
      this.$refs.smsMessage.saveSms()
    },
    cancel() {
      this.$refs.smsMessage.cancel()
      this.enableSave = false
    },
    backToSmsSend() {
      this.smsModalHeader = 'Send SMS'
      this.isSendSms = true
      this.isSendSmsModalActive = true
      this.isSmsMessageModalActive = false
    },
    setEnableSave(isShow) {
      this.enableSave = isShow
    },
    smsDetailsEntered(isEnableSend) {
      this.isEnteringSmsInfo = isEnableSend
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
