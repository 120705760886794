<template>
  <transition-group name="custom-classes-transition"
    enter-active-class="animated slideInLeft"
    leave-active-class="animated slideOutLeft">
    <div v-show="toggleSideMenu"
      style="height: 100%;"
      :style="{ 'min-width': inlineStyle }"
      class="tile is-parent"
      key="tile-parent">
      <article key="tile-child"
        class="tile is-child box detail-page-tile"
        :style="{ 'min-width': inlineStyle }">
        <span v-show="$user.info.isSupportUser && hasFlexiShopIntegration"
          class="icon is-synced-icon"
          :class="[ isSynced ? 'has-text-success' : 'has-text-warning' ]"
          style="">
          <i class="mdi mdi-circle-medium mdi-36px" />
        </span>
        <div v-if="subquotes && subquotes.length > 0"
          class="tabs">
          <ul>
            <li v-for="(sub) in subquotes"
              :key="sub.key"
              :class="{ 'is-active' : sub.key === currentsubquote }">
              <router-link :to="{ name: currentRouteName, params: { quoteId : sub.value }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': isNew && sub.key !== currentsubquote}"
                @click.native.prevent="goToRoute({ name: currentRouteName, params: { quoteId : sub.value }, query: $route.query })"
                exact>
                <span v-show="readOnlyStatuses[sub.key]"
                  style="margin-left: 0.1em; margin-right: 0.1em"
                  class="icon is-small has-text-info">
                  <i class="icon mdi mdi-lock-outline" />
                </span>
                <span :class="{ 'has-badge-warning has-badge-small has-badge-rounded has-badge-offset2': isNew && sub.key === currentsubquote }"
                  :data-badge="isNew && sub.key === currentsubquote ? '' : null">
                  {{ sub.key === 0 ? 'Main' : `Add-${sub.key}` }}
                </span>
              </router-link>
            </li>
          </ul>
          <button v-if="false"
            class="button is-pulled-right is-small is-turquoise"
            @click="gotoV1">
            <span class="icon">
              <i class="mdi mdi-arrow-right" />
            </span>
            <span>V1</span>
          </button>
        </div>
        <slot class="side-menu"
          name="side-menu"
          :style="{ 'min-width': inlineStyle }" />
      </article>
    </div>
  </transition-group>
</template>

<script>
import { EventHubTypes } from '@/enums'
import { CompanyService } from '@/services'

export default {
  name: 'AppQuoteSideMenu',
  props: {
    minWidth: {
      type: Number,
      default: 18
    },
    visibleWidth: {
      type: Number,
      default: 1008
    },
    fixedMenu: {
      type: Boolean,
      default: false
    },
    subquotes: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentsubquote: {
      type: Number,
      default: 0
    },
    currentRouteName: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    readOnlyStatuses: {
      type: Array,
      default: () => []
    },
    isSynced: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inlineStyle: '',
      windowWidth: 0,
      windowHeigth: 0,
      toggleSideMenu: true,
      isTouchDevice: false,
      subQuoteNo: 0,
      planningSetting: null
    }
  },
  computed: {
    hasFlexiShopIntegration() {
      return this.planningSetting && this.planningSetting.password
    }
  },
  watch: {
    minWidth: function (newVal, oldVal) {
      this.inlineStyle = this.minWidth.toString() + 'vw'
    }
  },
  created() {
    this.subQuoteNo = this.subquotes.find((s) => s.key === this.currentsubquote)
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, (data) => {
      this.handleSideMenuEvent(data)
    })
  },
  mounted() {
    this.inlineStyle = this.minWidth.toString() + 'vw'
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)

      this.getWindowWidth()
      this.getWindowHeight()
    })
    this.getCompanyExternalSetting()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowWidth(event) {
      this.windowWidth = window.innerWidth // document.documentElement.clientWidth
      if (this.toggleSideMenu && this.windowWidth <= this.visibleWidth) {
        this.toggleSideMenu = false
        this.isTouchDevice = true
        this.$eventHub.$emit(EventHubTypes.AutoHideSideMenu, true)
      } else if (!this.toggleSideMenu && this.windowWidth > this.visibleWidth) {
        this.toggleSideMenu = true
        this.isTouchDevice = false
        this.$eventHub.$emit(EventHubTypes.AutoHideSideMenu, false)
      }
    },
    getWindowHeight(event) {
      this.windowHeight = window.innerHeight // document.documentElement.clientHeight
    },
    handleSideMenuEvent(data) {
      this.toggleSideMenu = data || (this.fixedMenu && !this.isTouchDevice)
    },
    selectSubQuote(subQuoteNo) {
      this.subQuoteNo = subQuoteNo
      this.$emit('select-subquote', subQuoteNo)
    },
    goToRoute(route) {
      if (!this.isNew) {
        this.$router.push(route)
      }
    },
    gotoV1() {
      const quoteId = this.subquotes[this.currentsubquote].value
      window.open(`${process.env.VUE_APP_ROOT_URI}/modules/handlers/QuoteLoading.ashx?quoteId=${quoteId}&mode=5`, '_blank')
    },
    isLocked(subQuoteNo) {
      return this.readOnlyStatuses[subQuoteNo]
    },
    async getCompanyExternalSetting() {
      this.planningSetting = await CompanyService.getCompanyExternalSetting('Planning Plus')
    }
  }
}
</script>

<style lang="scss" scoped>
$animation-time: 0.5s;

.side-menu {
  min-height: 100%;
  max-width: 40vw;
}

.slideInLeft {
  -webkit-animation: slideInLeft $animation-time;
  /* Safari 4+ */
  -moz-animation: slideInLeft $animation-time;
  /* Fx 5+ */
  -o-animation: slideInLeft $animation-time;
  /* Opera 12+ */
  animation: slideInLeft $animation-time;
  /* IE 10+, Fx 29+ */
}

.slideOutLeft {
  -webkit-animation: slideOutLeft $animation-time;
  /* Safari 4+ */
  -moz-animation: slideOutLeft $animation-time;
  /* Fx 5+ */
  -o-animation: slideOutLeft $animation-time;
  /* Opera 12+ */
  animation: slideOutLeft $animation-time;
  /* IE 10+, Fx 29+ */
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}
.is-synced-icon {
  position: absolute;
  margin-top: -1em;
  margin-left: -1em;
}
</style>
